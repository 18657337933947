<template>
    <header id="Header">
        <div class="header__left-container">
            <button class="header-button__fullscreen" @click="handleFullscreen()">
                <svg v-show="!isFullscreen" role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24"> <path d="M10.5454 4V6H6V10.5453H4V4H10.5454Z" fill="currentColor"/> <path d="M4 13.4544V20H10.5454V18H6V13.4544H4Z" fill="currentColor"/> <path d="M18 13.4544V18H13.4544V20H20V13.4544H18Z" fill="currentColor"/> <path d="M20 10.5453V4H13.4544V6H18V10.5453H20Z" fill="currentColor"/> </svg>
                <svg v-show="isFullscreen" role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24"> <path d="M10 14H4V16H8V20H10V14Z" fill="currentColor"/> <path d="M14 14H20V16H16V20H14V14Z" fill="currentColor"/> <path d="M10 10H4V8H8L8 4H10V10Z" fill="currentColor"/> <path d="M14 10H20V8H16V4H14V10Z" fill="currentColor"/> </svg>
            </button>
            <LanguageSelector></LanguageSelector>
            <button class="header-button__back" :disabled="isHome" @click="handleBack()">
                <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" > <path d="M7.62127 11.4998L11.5709 7.5502L10.1567 6.13599L3.79286 12.4998L10.1567 18.8639L11.5709 17.4497L7.6212 13.4998H20.207V11.4998H7.62127Z" fill="currentColor"/> </svg>
                <span v-html="backText"></span> 
            </button>
            <Breadcrumbs></Breadcrumbs>
        </div>
        <Logo></Logo>
    </header>
</template>
<script>
import Logo from "../ui/Logo.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import LanguageSelector from "../ui/LanguageSelector.vue";

export default {
    components: {
        // Ui Components
        Logo,
        Breadcrumbs,
        LanguageSelector
    },
    data() {
        return {
            computeFullscreen: false,
        }
    },
    computed: {
        isFullscreen() {
            if (this.computeFullscreen) {
                this.computeFullscreen = false;
                return !app.api.Utils.isFullscreen();
            }

            // initial
            return app.api.Utils.isFullscreen();
        },
        isHome() {
            this.$route;
            return app.api.PageManager.onHomePage();
        },
        backText () {
            return app.api.PageManager.getCustomComponentFromType("buttonTexts").data.back
        }
    },
    methods: {
        handleFullscreen() {
            this.computeFullscreen = true;
            app.api.Utils.toggleFullscreen();
        },
        handleBack() {
            if (!this.isHome) {
                app.api.PageManager.navigateBack();
            }
        }
    }
}
</script>
<style lang="scss">

#Header {
    position: absolute;
    background-color: var(--clr-dark);
    height: var(--header-height);
    bottom: var(--header-inset);
    left: var(--header-inset);
    right: var(--header-inset);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-header);
}

.header__left-container {
    display: flex;
    align-items: center;
    height: var(--header-height);
}

.header-button__fullscreen,
.header-button__back,
.header-button__lang {
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--header-height);
    padding: 0 32px;
    position: relative;
    margin-right: 1px;
    color: var(--clr-light);
    transition: background .25s var(--tk-easeinout);

    &:hover {
        color: var(--clr-accent2);
        transition: background .25s var(--tk-easeinout);
    }

    &:after {
        $divider-height: 34px;
        content: '';
        height: $divider-height;
        position: absolute;
        right: -1px;
        top: calc((var(--header-height) - $divider-height) / 2);
        border: 1px solid var(--clr-light);
    }
    
    svg {
        width: 24px;
    }
}

.header-button__back {
    gap: 4px;
    padding-right: 40px;

    span {
        font-size: var(--caption-size);
        font-weight: var(--caption-weight);
        text-transform: var(--caption-transform);
    }

    &:disabled {

        &:hover {
            color: var(--clr-light);
            cursor: default;
        }

        svg,
        span {
            opacity: .4;
        }
    }
}
</style>