export default function BackgroundPreloader(app) {
    let backgrounds = [];
    
    return Object.freeze({
        preLoadBackgrounds
    })


    async function preLoadBackgrounds() {
        // Run preloader for all scene backgrounds
        getBackgroundAssets();
        await preloadBackgroundAssets();
        console.log("Background assets loaded for\n => ", backgrounds)
        return Promise.resolve();
    }
      


    function getBackgroundAssets() {
        // find all assets in scene backgrounds
        // BOTH images and videos.
        let tempBackgrounds = app.api.PageManager.getAllPages();
        tempBackgrounds.forEach(bg => {
            if (!bg.background)
                return

            backgrounds.push(findSrc(Object.entries(bg.background)))
        });
    }


    function findSrc(arr) {
        // loop through each element of the array
        for (const el of arr) {
            // if the current element is an array
            if (Array.isArray(el)) {
                // recursively call the findSrc function with the current element as argument
                const result = findSrc(el);
                // if the result is not null, return it
                if (result) {
                    return result;
                }
            }

            // if the current element is an object with a src property
            else if (el && typeof el === 'object' && 'src' in el) {
                // return the value of the src property
                return el.src;
            }
        }
        // return null if no src property is found
        return null;
    }

    async function preloadBackgroundAssets() {
        const promises = backgrounds.map((src) => {
          let fileType = app.api.Utils.getMediaType(src);
      
          if (fileType === 'video') {
            return app.api.Utils.preloadVideo(src);
          } else if (fileType === 'image') {
            app.api.Utils.preloadImage(src);
            return Promise.resolve();
          }
        });
      
        await Promise.all(promises);
      }
}