<template>
  <transition name="changesceneZoom" v-on:after-leave="transitionAfterLeave">
    <main class="Page" v-if="!animating" :style="{'transform-origin': transformOrigin}">
      <!-- Content Components -->
      <div class="Page__ContentComponents">
        <ComponentRender :components="contentComponents" :pageData="getPageData"></ComponentRender>
      </div>
      
      <!-- Hotspot layer -->
      <HotspotRenderer :hotspots="getPageData.children" @hotspotPressed="hotspotPressed" v-show="!hasContentComponentOpen"></HotspotRenderer>
      
      <!-- Scene Background Components -->
      <div class="Page__BackgroundComponents" :class="{'Page__BackgroundComponents--dimmed': hasContentComponentOpen}">
        <ComponentRender :components="backgroundComponents" :pageData="getPageData"></ComponentRender>
      </div>

      <!-- Scene Background -->
      <PageBackground :background="getPageData.data.data.background" :hasContentComponentOpen="hasContentComponentOpen" @dblclick="toggleFullscreen" @click="backgroundClicked"></PageBackground>
    </main>
  </transition>
</template>


<script>
import ComponentRender from "../ComponentRender.vue";
import PageBackground from '../page/PageBackground.vue';
import HotspotRenderer from "../hotspots/HotspotRenderer.vue";

export default {
  components: {
    HotspotRenderer,
    ComponentRender,
    PageBackground
  },
  data() {
    return {
      backgroundComponents: [],
      contentComponents: [],
      onHomePage: false,
      animating: false,
      transformOrigin: ''
    }
  },
  async mounted() {
    this.getBackgroundComponents();
    this.onHomePage = app.api.PageManager.onHomePage();
    this.handleAutoOpenComponents(this.$router.currentRoute._value.fullPath);
    await this.handleComponents(this.$route.fullPath.toLowerCase())
  },
  async beforeRouteUpdate(to, from) {
    var samePage = to.fullPath.split(":")[0] === from.fullPath.split(":")[0];
    await this.handleComponents(to.fullPath.toLowerCase())

    if (!samePage) {
      this.animating = true;
    }

    setTimeout(() => {
      this.backgroundComponents = this.getPageData.components;
      this.onHomePage = app.api.PageManager.onHomePage();
      if (!samePage)
        this.handleAutoOpenComponents(to.fullPath)
    }, 0);
  },
  methods: {
    getBackgroundComponents() {
      this.getPageData.components.forEach(component => {
        this.backgroundComponents.push(component);
      });
    },
    transitionAfterLeave() {
      this.animating = false;
      this.transformOrigin = '';
      this.handleAutoOpenComponents(this.$router.currentRoute._value.fullPath);
    },
    hotspotPressed(e) {
      this.transformOrigin = `${e.data.positionData.xPosition}px ${e.data.positionData.yPosition}px`
    },

    // CONTENT COMPONENTS
    async handleComponents(route) {
      let tempRoute = route;
      if(tempRoute.includes(":")) {
        tempRoute = this.removeDublicateComponents(route);
      }

      this.checkRouteAndOpenContentComponents(tempRoute);

      await this.$nextTick()
    },
    checkRouteAndOpenContentComponents(route) {
       // Get all content components from route
      var routeComponentSlugs = route.split(":").slice(1);

      this.contentComponents = [];

      // add new components to the contentComponents array.
      routeComponentSlugs.map((slug) => {
          let component = app.api.PageManager.getContentComponentInManifest(slug) ? app.api.PageManager.getContentComponentInManifest(slug) : app.api.PageManager.getContentComponent(slug);
          
          return {
            id: component.id,
            title: component.title ? component.title : component.name,
            type: component.data.type ? component.data.type : component.type,
            properties: component.data.data ? component.data.data : component.data,
            slug
          }
        }).forEach((c) => {
          console.log(`Content Component => \nID: ${c.id} - ${c.title}\nType: ${c.type}`)
          this.contentComponents.push(c);
        });
    },
    removeDublicateComponents(route) {
      // Get all content components from route
      let sceneRoute = route.split(":").slice(0)[0];
      let componentSlugs = route.split(":").slice(1);

      // Remove dublicate component slugs
      componentSlugs = [...new Set(componentSlugs)].join(":");

      return sceneRoute + ":" + componentSlugs;
    },
    handleAutoOpenComponents(route) {
      // setTimeout wrapper to make sure hotspots have been update with the necessary variables
      setTimeout(() => {
        let sceneRoute = route.split(":").slice(0)[0];
        let componentSlugs = route.split(":").slice(1);

        // Check if any components are already open
        if (componentSlugs.length > 0) 
          return

        // Find all slugs for hotspots with the boolean autoOpen 
        var autoOpenComponents = app.api.PageManager.getPage(sceneRoute).children.filter(hotspot => hotspot.data.type.autoOpen === true).map(component => {
          var slug = component.route.split(":").slice(1)[0];
          if(!componentSlugs.includes(slug)) 
            return slug;
        }).filter(component => component !== undefined)
        

        if(autoOpenComponents.length > 0)
          app.api.PageManager.navigateToRouteSilent(sceneRoute + ":" + autoOpenComponents.join(":"))
      }, 1);
    },
    toggleFullscreen() {
      app.api.Utils.toggleFullscreen()
    },
    backgroundClicked() {
      if(this.hasContentComponentOpen)
        app.api.PageManager.closeAllContentComponents();
    }
  },
  computed: {
    getPageData() {
      return app.api.PageManager.getPage(this.$route.path) || {};
    },
    hasContentComponentOpen() {
      return this.contentComponents.length > 0
    },
    activeHotspot() {
      this.$route;
      return this.getPageData.children.find(hotspot => hotspot.route == this.$router.currentRoute._value.path)
    }
  },
}
</script>

<style lang="scss">
.Page {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .Page__BackgroundComponents, .Page__ContentComponents {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &>* {
      position: absolute;
      pointer-events: all;
    }
  }

  .Page__BackgroundComponents {
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      pointer-events: none;
      z-index: 1000;
      transition: ease 0.25s opacity;
    }

    &--dimmed {
      pointer-events: none;
      * {
        pointer-events: none;
      }
      &::after {
          opacity: 0.5;
      }
    }
  }

  .Page__ContentComponents {
    z-index: 3;
  }

  &>*{
    pointer-events: all;
  }
}



/* default scene change animation */
.changesceneZoom-enter-active {
  transition-property: transform, opacity, filter;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.changesceneZoom-leave-active {
  transition-property: transform, opacity, filter;
  transition-duration: 0.45s;
  transition-timing-function: ease-in-out;
}

.changesceneZoom-enter-from {
  opacity: 0;
  transform: scale(2);
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.changesceneZoom-leave-to {
  opacity: 0;
  transform: scale(2.5);
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

</style>