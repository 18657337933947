<template>
  <nav id="UiLayer">
    <Header></Header>
  </nav>
</template>
<script>
import Header from "../ui/Header.vue";

export default {
  components: {
    // Ui Components
    Header,
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<style lang="scss">
#UiLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  * {
    pointer-events: all;
  }
}
</style>