<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenVideo" v-if="ready" :class="{'fullscreenVideo--videopaused': isVideoPaused}">
        <video ref="video"
            v-if="properties.video.src"
            :autoplay="properties.autoplay"
            :controls="properties.controls"
            :mute="properties.mute"
            :loop="properties.loop"
            :poster="posterUrl"
            @playing="isVideoPaused = false"
            @pause="isVideoPaused = true"
            type="video/mp4"
            playsinline
            webkit-playsinline
            crossorigin>
            <source :src="videoUrl">
        </video>

        <button class="fullscreenVideo__close" @click="closeComponent">
          <span v-html="closeText"></span>
          <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" > <path d="M5.41425 4.01465L20.9706 19.571L19.5564 20.9852L4.00003 5.42886L5.41425 4.01465Z" fill="currentColor"/> <path d="M4 19.571L19.5563 4.01465L20.9706 5.42886L5.41421 20.9852L4 19.571Z" fill="currentColor"/> </svg>
        </button>
    </article>
  </transition>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      isVideoPaused: true
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    closeComponent() {
      app.api.PageManager.navigateBack();
    }
  },
  computed: {
    videoUrl() {
      return app.api.Utils.getMediaPath(this.properties.video.src)
    },
    posterUrl() {
      return app.api.Utils.getMediaPath(this.properties.poster.src)
    },
    closeText () {
        return app.api.PageManager.getCustomComponentFromType("buttonTexts").data.close
    }
  }
};
</script>

<style lang="scss">
.fullscreenVideo {
  top: 20px;
  height: 943px;
  width: calc(100% - (var(--header-inset) * 2));
  background: var(--clr-dark);
  display: flex;
  justify-content: center;
  align-items: center;

  &>video,&>iframe {
    height: 100%;
  }
  
  iframe {
    aspect-ratio: 16 / 9;
  }

  &::after {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
    pointer-events: none;
    background-image: url(@/assets/img/ui/play.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 3;
  }
  
  &::before {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 120px;
    height: 120px;
    pointer-events: none;
    border-radius: 20px;
    background: var(--clr-dark);
    opacity: 0.3;
    z-index: 3;
  }

  &--videopaused {
      video {
          cursor: pointer;
      }

      &::after,&::before {
          content: '';
      }
  }
}

.fullscreenVideo__close {
  height: 48px;
  background-color: var(--clr-primary);
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--clr-light);
  font-size: var(--caption-size);
  font-weight: var(--caption-weight);
  text-transform: var(--caption-transform);

  img, svg {
    width: 24px;
    height: 24px;
    margin: 0 -7px;
  }

  &:hover {
    color: var(--clr-accent2);
  }
}
</style>