<template>
    <router-link :to="homeURL" id="logo">
        <img src="@/assets/img/ui/logo.png">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getHomePage().route
        }
    }
}
</script>
<style lang="scss" scoped>
    #logo {
        width: 153px;
        cursor: pointer;
        margin-right: 44px;
        display: flex;
        align-items: center;
        
        &>img {
            width: 100%;
        }
    }
</style>