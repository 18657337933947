<script>
export default {
  render: () => null,
  props: [
    "properties",
    "pageData"
  ],
  mounted() {
    window.open(this.properties.externalUrl, '_blank');
    app.api.PageManager.closeAllContentComponents();
    this.$router.go();
  },
};
</script>