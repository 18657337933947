<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenIframePlayer fullscreenVideo" v-if="ready">
      <iframe ref="youtube"
        v-if="properties.youtubeUrl !== '' && !isOffline"
        :src="youtubeUrl"
        frameborder="0"
        allow="autoplay;"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>

      <video ref="video"
        v-else-if="isOffline && properties.offlineVideo.src"
        controls 
        autoplay
        type="video/mp4"
        playsinline
        webkit-playsinline
        crossorigin>
        <source :src="offlineVideoUrl">
      </video>
      
      <button class="fullscreenVideo__close" @click="closeComponent">
        <span v-html="closeText"></span>
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" > <path d="M5.41425 4.01465L20.9706 19.571L19.5564 20.9852L4.00003 5.42886L5.41425 4.01465Z" fill="currentColor"/> <path d="M4 19.571L19.5563 4.01465L20.9706 5.42886L5.41421 20.9852L4 19.571Z" fill="currentColor"/> </svg>
      </button>
    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      youtubeID: ''
    }
  },
  created() {
    if(this.properties.youtubeUrl !== '') 
      this.youtubeID = this.getYoutubeID(this.properties.youtubeUrl);
  },
  mounted() {
    this.ready = true
  },
  methods: {
    getYoutubeID(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    },
    closeComponent() {
      app.api.PageManager.navigateBack();
    },
    
  },
  computed: {
    youtubeUrl () {
      if (this.properties.autoplay) {
        return `https://www.youtube.com/embed/${this.youtubeID}?autoplay=1`
      } else {
        return `https://www.youtube.com/embed/${this.youtubeID}`
      }
    },
    closeText () {
        return app.api.PageManager.getCustomComponentFromType("buttonTexts").data.close
    },
    offlineVideoUrl() {
      return app.api.Utils.getMediaPath(this.properties.offlineVideo.src)
    },
    isOffline() {
      return app.api.Utils.isElectronApp()
    }
  }
};
</script>