<template>
    <transition name="HotspotEntrance">
        <!-- <router-link class="Hotspot subtleHotspot" v-show="ready" :to="hotspot.route" :class="getHotspotClasses" :style="[getPosition]" @click="hotspotPressed"> -->
        <div class="Hotspot subtleHotspot" v-show="ready" :class="getHotspotClasses" :style="[getPosition]">
            <span v-html="hotspot.data.hotspotAlternativeTitle !== '' ? hotspot.data.hotspotAlternativeTitle : hotspot.data.title"></span>
            <div class="subtleHotspot__corner-container">
                <img src="@/assets/img/ui/subtleHotspotCorner.svg">
                <img src="@/assets/img/ui/subtleHotspotCorner.svg">
                <img src="@/assets/img/ui/subtleHotspotCorner.svg">
                <img src="@/assets/img/ui/subtleHotspotCorner.svg">
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotClasses() {
            return {
                'Hotspot--hidden': this.hotspot.data.hidden
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 26px;

.subtleHotspot {
    box-shadow: none;
    cursor: default !important;
    
    span {
        height: $hotspot-height;
        padding-inline: 7px;
        background: rgba(255, 255, 255, 0.36);
        color: #415E6D;
        position: relative;
        z-index: 2;
    }
    
    .subtleHotspot__corner-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;

        img {
            position: absolute;

            &:nth-child(1) {
                top: -3px;
                left: -3px;
            }

            &:nth-child(2) {
                top: -3px;
                right: -3px;
                transform: rotate(90deg);
            }

            &:nth-child(3) {
                bottom: -3px;
                right: -3px;
                transform: rotate(180deg);
            }

            &:nth-child(4) {
                bottom: -3px;
                left: -3px;
                transform: rotate(270deg);
            }
        }
    }
}

</style>