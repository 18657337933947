<template>
    <div class="articleNavigation" v-if="ready" :class="{ 'collapsed': isCollapsed }" >

      <!-- Fold button -->
      <button class="articleNavigation__button" @click="toggleCollapsed()">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" > <path d="M7.62127 11.4998L11.5709 7.5502L10.1567 6.13599L3.79286 12.4998L10.1567 18.8639L11.5709 17.4497L7.6212 13.4998H20.207V11.4998H7.62127Z" fill="currentColor"/> </svg>
        <transition name="articleNavigationButton">
          <span v-show="!isCollapsed" v-html="closeText"></span>
        </transition>
      </button>

      <!-- Unfolded content -->
      <transition name="articleNavigationFold">
        <div class="articleNavigation__nav-container" v-show="!isCollapsed">

          <!-- Anchors -->
          <div class="articleNavigation__anchor-container">
            <h2 class="articleNavigation__anchor-title">{{ content.anchorTitle }}</h2>
            <ul class="articleNavigation__anchor-list">
              <li class="articleNavigation__anchor-item"
                v-for="anchor in anchors"
                :key="'anchor' + anchor.index"
              >
                <button href="#"
                  @click="emitScrollTo(anchor.index)"
                >{{ anchor.text }}</button>
              </li>
            </ul>
          </div>

          <!-- Links -->
          <!-- <div class="articleNavigation__link-container">
            <h2 class="articleNavigation__link-title">{{ content.linksTitle }}</h2>
            <ul class="articleNavigation__link-list">
              <li class="articleNavigation__link-item"
                v-for="link in content.links"
                :key="link.title"
              >
                <a :href="link.linkUrl" target="_blank"><img src="@/assets/img/ui/download.svg">{{ link.title }}</a>
              </li>
            </ul>
          </div> -->

        </div>
      </transition>

    </div>
</template>

<script>

export default {
  props: [
    "content",
    "anchors",
  ],
  data() {
    return {
      ready: false,
      isCollapsed: false
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
      this.emitCollapse(this.isCollapsed);
    },
    emitScrollTo(index) {
      this.$emit('scrollTo', index);
    },
    emitCollapse(boolean) {
      this.$emit('collapse', boolean);
    }
  },
  computed: {
    closeText () {
        return app.api.PageManager.getCustomComponentFromType("buttonTexts").data.close
    }
  }
};
</script>

<style lang="scss">
.articleNavigation {
  width: 412px;
  height: 100%;
  background: var(--clr-primary);
  color: var(--clr-light);
  padding: 30px;
  display: flex;
  flex-direction: column;
  transition-property: width, padding;
  transition: .5s var(--tk-easeinout);

  &.collapsed {
    width: 84px;
    padding: 30px 0px;

    .articleNavigation__button {
      margin-left: 50%;
      transform: translateX(-50%);
      transition-property: margin-left, transform;
      transition: .5s var(--tk-easeinout);
      
      img, svg {
        transform: rotate(180deg);
      }

      span {
        margin-left: 0px;
        transition: margin-left .5s var(--tk-easeinout);
      }
    }
  }
}

.articleNavigation__button {
  display: flex;
  justify-content: center;
  align-items: center;
  // 104px magic number width of button
  margin-left: calc(100% - 104px);
  padding: 12px;
  margin-top: -12px;
  margin-right: -12px;

  font-size: 14px;
  font-weight: var(--wgt-medium);
  text-transform: var(--caption-transform);
  
  img, svg {
    width: 24px;
  }

  span {
    margin-left: 10px;
    transition: margin-left .5s var(--tk-easeinout);
  }

  &:hover {
    color: var(--clr-accent2);
  }
}

.articleNavigation__nav-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: calc(412px - (30px * 2));
}

.articleNavigation__anchor-container {
  height: 100%;
}

.articleNavigation__link-container {
  flex-shrink: 0;
}

$padding-inline: 10px;
$padding-block: 20px;

.articleNavigation__anchor-title,
.articleNavigation__link-title {

  font-size: 23px;
  font-weight: var(--wgt-regular);
  text-align: left;
  margin-top: 60px;
  padding: 0 $padding-inline $padding-block $padding-inline;
  // border-bottom: 1px solid var(--clr-light);
  
  &:after {
    content: "";
    display: block;
    position: relative;
    left: -$padding-inline;
    bottom: -$padding-block;
    width: calc(100% + ($padding-inline * 2));
    height: 1px;
    background-color: var(--clr-light);
    opacity: .3;
  }
}

.articleNavigation__anchor-list,
.articleNavigation__link-list {
  padding: $padding-block $padding-inline;
}


.articleNavigation__anchor-item,
.articleNavigation__link-item {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: none;
  }
}

// .articleNavigation__anchor-list {}

.articleNavigation__anchor-item {
  margin-bottom: 24px;
  text-align: left;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 18px;
    height: 2px;
    background-color: var(--clr-light);
    margin-right: 15px;
  }

  &:hover {
    color: var(--clr-accent2);
  }
}

.articleNavigation__link-item {
  text-align: left;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    img {
      width: 24px;
    }
  }
}

.articleNavigationFold-enter-active,
.articleNavigationFold-leave-active {
  transition: opacity .5s var(--tk-easeinout);
}

.articleNavigationFold-enter-from,
.articleNavigationFold-leave-to {
  opacity: 0;
}

.articleNavigationButton-enter-active,
.articleNavigationButton-leave-active {
  transition-property: opacity, width;
  transition: .5s var(--tk-easeinout);
}

.articleNavigationButton-enter-from,
.articleNavigationButton-leave-to {
  opacity: 0;
  width: 0;
}

</style>