<template>
    <div id="LanguageSelector" v-if="showLanguageSelector">
        <button class="header-button__lang" @click="togglePopup">
            <img :src="iconURL(selectedLanguage.icon.src)">
            {{selectedLanguage.title}}
        </button>
        <transition name="toggleLangPopup">
            <div v-if="popupOpen">
                <button v-for="lang in languages" :key="lang.languageCode" @click="selectLanguage(lang.languageCode)">
                    <img :src="iconURL(lang.icon.src)">
                    {{lang.title}}
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            popupOpen: false,
            languages: [],
            showLanguageSelector: true
        } 
    },
    created() {
        this.showLanguageSelector = app.api.PageManager.getCustomComponentFromType("availableLanguages").data.showLanguageSelector;
        if(this.showLanguageSelector === false && app.api.store.get("lang") !== 'en') {
            this.selectLanguage('en')
        }
        this.formatLanguages();
    },
    methods: {
        formatLanguages() {
            this.languages = app.api.PageManager.getCustomComponentFromType("availableLanguages").data.languages;
        },
        getCurrentLang() {
            return app.api.store.get("lang");
        },
        selectLanguage(lang) {
            app.api.store.set("lang", lang);
            location.reload();
        },


        togglePopup() {
            this.popupOpen = !this.popupOpen;
        },
        closePopup() {
            this.popupOpen = false;
        },
        iconURL(src) {
            return app.api.Utils.getMediaPath(src)
        },
    },
    computed: {
        selectedLanguage() {
            return this.languages.find(lang => lang.languageCode == this.getCurrentLang())
        }
    }
}
</script>

<style lang="scss">

#LanguageSelector {
    position: relative;
    
    button {
        justify-content: flex-start;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;

        img {
            margin-right: 16px;
        }
    }

    &>div {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        background-color: var(--clr-dark);
        bottom: calc(100% + 16px);
        padding: 12px 0 12px 32px;
        
        button {
            display: flex;
            align-items: center;
            color: inherit;
            padding: 6px 0;
            margin-bottom: 2px;
            color: var(--clr-light);


            :last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

/* default Content Component Transition (background components have no transition by default)*/
.toggleLangPopup-enter-active {
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleLangPopup-leave-active {
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleLangPopup-enter-from {
  transform: translateY(10px);
  opacity: 0;
}

.toggleLangPopup-leave-to {
  opacity: 0;
}
    
</style>