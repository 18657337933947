<template>
    <section class="articleLinks" v-if="ready">

      <h1 class="articleLinks__title">
        {{ content.title }}
      </h1>

      <p class="articleLinks__guide-text">{{ content.guideText }}</p>

      <div class="articleLinks__links-container">
        <a class="articleLinks__link"
          v-for="(link, index) in content.links"
          :key="'links' + index"
          :href="link.linkUrl"
          :class="{'articleLinks__link--offline': isOffline}"
          target="_blank"
        >
          <img :src="qrUrls[index]">
          <p>{{ link.linkTitle }}</p>
        </a>
      </div>
      
    </section>
</template>

<script>

export default {
  props: [
    "content",
  ],
  data() {
    return {
      ready: false,
    }
  },
  computed: {
    qrUrls() {
      return this.content.links.map((link) => {
        return app.api.Utils.getMediaPath(link.qr.src);
      })
    },
    isOffline() {
      return app.api.Utils.isElectronApp()
    }
  },
  mounted() {
    this.ready = true
  }
};
</script>

<style lang="scss">
// .articleLinks {
//   border: 1px solid blue;
//   .articleLinks__info-boxes {
//     border: 1px solid purple;
//   }
//   .articleLinks__info-box {
//     border: 1px solid red;
//   }
// }

.articleLinks {
  width: var(--article-segment-width);
  height: 100%;
  padding-top: var(--article-top-padding);
  padding-bottom: var(--article-bottom-padding);
  overflow: hidden;
}

.articleLinks__title {
  text-align: left;
  font-size: 26px;
  font-weight: var(--wgt-regular);
  color: var(--clr-primary);
  line-height: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--clr-primary);
  margin-bottom: 30px;
}

.articleLinks__guide-text {
  text-align: left;
  font-size: 14px;
  font-weight: var(--wgt-medium);
  color: var(--clr-secondary);
  line-height: 16px;
  margin-bottom: 20px;
}

.articleLinks__links-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.articleLinks__link {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  img {
    width: 80px;
  }

  p {
    text-align: left;
  }

  &--offline {
    pointer-events: none;
  }

  &:hover {
    color: var(--clr-accent2);
  }
}

.articleLinks__points-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.articleLinks__point {
  width: 33.3%;
  border-left: 2.5px solid var(--clr-primary);
  padding-left: 20px;

  h3 {
    text-align: left;
    font-size: 16px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: var(--wgt-bold);
    color: var(--clr-secondary);
    margin-bottom: 24px;
  }

  p {
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    font-weight: var(--wgt-regular);
    color: var(--clr-primary);
  }
}
</style>