<template>
	<div class="PageBackground" :class="{'PageBackground--dimmed': hasContentComponentOpen}">
		<video :src="backgroundVideo"
            v-if="hasVideo"
            muted 
            autoplay 
            loop 
            playsinline
            webkit-playsinline
			type="video/mp4"
            @contextmenu.prevent=""
		></video>
        <img v-if="hasImage" :src="backgroundImage" :alt="background.image.name">
	</div>
</template>

<script>
	export default {
		props: ["background", "hasContentComponentOpen"],
        computed: {
            hasVideo () {
                return Object.keys(this.background)[0] === 'video'
            },
            backgroundVideo() {
                if (this.hasVideo) {
                    return app.api.Utils.getMediaPath(this.background.video.src)
                } else {
                    return null;
                }
            },
            hasImage() {
                return Object.keys(this.background)[0] === 'image'
            },
            backgroundImage() {
                if (this.hasImage) {
                    return app.api.Utils.getMediaPath(this.background.image.src);
                } else {
                    return null;
                }
            }
        }
	};
</script>

<style lang="scss" scoped>
    .PageBackground {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: -1;

        & > * {   
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
</style>