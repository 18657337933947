import config from "@/config"

export default function makeDataStore(app) {
    if ('store' in app.api) {
        throw Error('store already defined in app.api')
    }

    let data = initializeStorage()

    app.api.store = Object.freeze({
        get,
        set,
        remove,
        has,
        list,
    })

    function initializeStorage() {
        if (!localStorage.getItem(config.name)) {
            let initialData = {
                username: "",
                lang: config.language,
                data: {}
            }

            localStorage.setItem(config.name, JSON.stringify(initialData))
            return initialData
        }
        return JSON.parse(localStorage.getItem(config.name));
    }

    function set(key, keyVal) {
        data[key] = keyVal
        save()
        return data[key]
    }

    function get(key) {
        if (has(key))
            return JSON.parse(localStorage.getItem(config.name))[key];
        return false;
    }

    function has(key) {
        var o = JSON.parse(localStorage.getItem(config.name)) || {};
        return (key in o);
    }

    function remove(key, prop) {
        if (has(key)) {
            if (prop && prop in data[key]) {
                delete data[key][prop]
                save({ key: `${key}.${prop}`, action: 'remove' })
            } else {
                delete data[key]
                save({ key, action: 'remove' })
            }
            return true;
        }
        return false
    }

    function list() {
        return Object.keys(data);
    }

    function save() {
        localStorage.setItem(config.name, JSON.stringify(data));
    }
}