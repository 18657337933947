<template>
    <section class="articleVideo" v-if="ready">

      <div class="articleVideo__video-container" :style="{ 'order': content.isVideoAfter ? 2 : 0}">
        <video class="articleVideo__video"
          ref="video"
          v-if="content.posterVideo"
          autoplay
          muted
          loop
          :poster="posterUrl"
          @playing="isVideoPaused = false"
          @pause="isVideoPaused = true"
          type="video/mp4"
          playsinline
          webkit-playsinline
          crossorigin>
          <source :src="videoUrl">
        </video>
        <img class="articleVideo__image" v-if="!content.posterVideo" :src="posterUrl">
        <div class="articleVideo__video-overlay"
          v-if="content.videoLink"
          @click="openFullscreenVideo(content.videoLink)"
        >
          <img src="@/assets/img/ui/play.svg">
        </div>
      </div>

      <div class="articleVideo__text-container">
        <h2>{{ content.title }}</h2>
        <div v-html="content.text"></div>
      </div>

    </section>
</template>

<script>

export default {
  props: [
    "content",
  ],
  data() {
    return {
      ready: false,
      isVideoPaused: true
    }
  },
  mounted() {
    this.ready = true
  },
  computed: {
    videoUrl() {
      if (this.content.posterVideo) {
        return app.api.Utils.getMediaPath(this.content.posterVideo.src)
      } else {
        return null;
      }
    },
    posterUrl() {
      return app.api.Utils.getMediaPath(this.content.posterImage.src)
    }
  },
  methods: {
    openFullscreenVideo(id) {
      app.api.PageManager.openExplicitContentFromID(id)
    }
  }
};
</script>

<style lang="scss">
// .articleVideo {
//   border: 1px solid blue;
// }

.articleVideo {
  width: var(--article-segment-width);
  height: 100%;
  padding-top: var(--article-top-padding);
  padding-bottom: var(--article-bottom-padding);
  overflow: hidden;

  // change order functionality
  display: flex;
  flex-direction: column;
  gap: 30px;
  // order on components with v-bind depending on manifest data
}

.articleVideo__video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  
  // change order functionality
  // default 0
  order: 0;
}

.articleVideo__video,
.articleVideo__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.articleVideo__video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 52px;
  }
}

.articleVideo__text-container {
  // change order functionality
  order: 1;

  h2 {
    font-size: var(--subheading-size);
    font-weight: var(--subheading-weight);
    line-height: 1.38;
    margin-bottom: 24px;
    text-align: left;
  }
  
  p {
    font-size: var(--body-size);
    font-weight: var(--body-weight);
    line-height: 1.56;
    text-align: left;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>