<template>
  <transition name="toggleContentComponent">
    <div class="horizontalArticle__wrapper" v-show="ready">
      <article class="horizontalArticle">

        <!-- Navigation -->
        <articleNavigation class="horizontalArticle__navigation"
          v-if="properties.navigation"
          :content="properties.navigation"
          :anchors="segmentAnchors"
          @scrollTo="scrollToSegment"
          @collapse="calcIndicatorWidth"
        ></articleNavigation>

        <!-- Article scrollViewItem -->
        <div class="horizontalArticle__segment-wrapper" ref="scrollViewSegments">
          <section class="horizontalArticle__segment"
            :id="'horizontalArticle__segment--' + index"
            v-for="(content, index) in properties.content"
            :key="'segment' + index"
          >
            <articleHeader v-if="content.nsAlias === 'articleHeader'" :content="content"></articleHeader>
            <articleVideo v-else-if="content.nsAlias === 'articleVideo'" :content="content"></articleVideo>
            <articleImage v-else-if="content.nsAlias === 'articleImage'" :content="content"></articleImage>
            <articleLinks v-else-if="content.nsAlias === 'articleLinks'" :content="content"></articleLinks>
          </section>

        </div>
        
        <!-- Absolute Elements -->
        <button class="horizontalArticle__close" @click="closeComponent" ref="scrollViewButton">
          <span v-html="backText"></span>
          <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" > <path d="M5.41425 4.01465L20.9706 19.571L19.5564 20.9852L4.00003 5.42886L5.41425 4.01465Z" fill="currentColor"/> <path d="M4 19.571L19.5563 4.01465L20.9706 5.42886L5.41421 20.9852L4 19.571Z" fill="currentColor"/> </svg>
        </button>
        
        <div class="horizontalArticle__scroll-indicator-container" ref="scrollViewIndicator">
          <div class="horizontalArticle__scroll-indicator" ref="scrollIndicator"></div>
        </div>
      </article>
    </div>
  </transition>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      dragPosition: {
        left: 0,
        x: 0,
      }
    }
  },
  computed: {
    segmentAnchors() {
      // map segment anchorTexts, then filter for empty
      return this.properties.content.map((segment, index) => {
        return {
          text: segment.anchorText,
          index: index
        };
      }).filter((anchor) => {
        return anchor.text.length > 0;
      });

      let anchors = [];
      this.properties.content.forEach(segment => {
        anchors.push
      });
      let titles = [];

      this.properties.content.forEach(element => {
        const firstWord = element.title.substring(0, element.title.indexOf(' '));
        titles.push(firstWord);
      });

      return anchors;
    },
    backText () {
        return app.api.PageManager.getCustomComponentFromType("buttonTexts").data.back
    }
  },
  mounted() {
    this.ready = true;

    // add eventlisteners to elements inside scrollable view
    const scrollViewItems = this.getScrollViewItems();
    scrollViewItems.forEach(item => {
      item.addEventListener("wheel", this.handleWheelEvent);
      item.addEventListener("scroll", this.handleScrollEvent);
      item.addEventListener("mousedown", this.handleMouseDownEvent);
    });

    // initiate progress bar
    this.calcIndicatorWidth(false);
  },
  beforeUnmount() {
    // remove eventlisteners from elements inside scrollable view
    const scrollViewItems = this.getScrollViewItems();
    scrollViewItems.forEach(item => {
      item.removeEventListener("wheel", this.handleWheelEvent);
      item.removeEventListener("scroll", this.handleScrollEvent);
      item.removeEventListener("mousedown", this.handleMouseDownEvent);
    });
  },
  methods: {
    closeComponent() {
      app.api.PageManager.navigateBack();
    },
    scrollToSegment(index) {
      const segment = document.querySelector('#horizontalArticle__segment--' + index);
      this.$refs.scrollViewSegments.scrollLeft = segment.offsetLeft;
    },
    calcIndicatorWidth(isCollapsed) {
      // !!! Calculates the width of the scrollIndicator container.
      // the scrollIndicator is calculated in handleScrollEvent
      const offsetX = 180;
      let navWidth = 0;
      if (this.properties.navigation) {
        if (isCollapsed) {
          navWidth = 84;
        } else {
          navWidth = 412;
        }
      }
      this.$refs.scrollViewIndicator.style.width = `calc(100% - ${navWidth}px - (${offsetX}px * 2))`
    },
    getScrollViewItems() {
      return [
        this.$refs.scrollViewSegments,
        this.$refs.scrollViewButton,
        this.$refs.scrollViewIndicator,
      ]
    },
    handleWheelEvent(event) {
      event.preventDefault();
      this.$refs.scrollViewSegments.scrollLeft += event.deltaY;
    },
    handleScrollEvent(event) {
      const scrollLeft = this.$refs.scrollViewSegments.scrollLeft;
      const scrollWidth = this.$refs.scrollViewSegments.scrollWidth - this.$refs.scrollViewSegments.clientWidth;
      const scrollPercentage = (scrollLeft / scrollWidth) * 100;
      this.$refs.scrollIndicator.style.width = scrollPercentage + '%';
    },
    handleMouseDownEvent(event) {
      // Update the global dragPos before moving on.
      this.dragPosition = {
          left: this.$refs.scrollViewSegments.scrollLeft,
          x: event.clientX,
      };

      // Handle some UX
      const scrollViewItems = this.getScrollViewItems();
      scrollViewItems.forEach(item => {
        item.style.cursor = 'grabbing';
        item.style.userSelect = 'none';
      });
      
      // Accomodate mouseEvents for entire window, not just container.
      window.addEventListener("mousemove", this.handleMouseMoveEvent);
      window.addEventListener("mouseup", this.handleMouseUpEvent);
    },
    handleMouseMoveEvent(event) {
      // How far the mouse has been moved
      const deltaX = event.clientX - this.dragPosition.x;

      // Scroll the element
      this.$refs.scrollViewSegments.scrollLeft = this.dragPosition.left - deltaX;
    },
    handleMouseUpEvent(event) {

      // Handle some UX
      const scrollViewItems = this.getScrollViewItems();
      scrollViewItems.forEach(item => {
        item.style.removeProperty('cursor');
        item.style.removeProperty('user-select');
      });

      // remove events
      window.removeEventListener("mousemove", this.handleMouseMoveEvent);
      window.removeEventListener("mouseup", this.handleMouseUpEvent);
    },
    
  }
};
</script>

<style lang="scss">
.horizontalArticle__wrapper {
  width: 100%;
  height: 100%;
  padding: var(--header-inset);
  padding-bottom: calc(var(--header-height) + (var(--header-inset) * 2));
  // wrapper never needs pointer events. It negates built in content functionality
  pointer-events: none !important;
}

.horizontalArticle {
  width: 100%;
  height: 100%;
  background: var(--clr-light);
  pointer-events: all;
  position: relative;
  overflow-x: hidden;
  display: flex;
}

.horizontalArticle__navigation {
  flex-shrink: 0;
}

// this wrapper is solely here because of scrollbar styling
.horizontalArticle__segment-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: none;
  position: relative;
  // margin-bottom: 60px;

  // Scrollbar styling
  &::-webkit-scrollbar {
    display: none;
  }
  // scrollbar-color: var(--clr-accent-soft) var(--clr-accent-soft30);
  // scrollbar-width: thin;

  // // This solution is a bit hacky, but it works
  // $scrollbar-inset: 60px;
  // $scrollbar-height: 5px;

  // &::-webkit-scrollbar {
  //   height: calc($scrollbar-height + $scrollbar-inset);
  // }

  // &::-webkit-scrollbar-track {
  //   background: transparent;
  //   border-top: $scrollbar-height solid var(--clr-accent-soft30);
  //   margin: 50px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: var(--clr-accent-soft);
  //   border-bottom: $scrollbar-inset solid transparent;
  //   background-clip: padding-box;

  //   &:hover {
  //     background: var(--clr-secondary);
  //     background-clip: padding-box;
  //   }
  // }
}

.horizontalArticle__segment {
  height: 100%;
  padding-inline: calc(var(--article-gap) / 2);
  padding-bottom: 45px;

  // Display flex to disable collapsable margins
  display: flex;
}

.horizontalArticle__close {
  height: 48px;
  background-color: var(--clr-dark);
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--clr-light);
  font-size: var(--caption-size);
  font-weight: var(--caption-weight);
  text-transform: var(--caption-transform);

  img, svg {
    width: 24px;
    height: 24px;
    margin: 0 -7px;
  }
  
  &:hover {
    color: var(--clr-accent2);
  }
}

.horizontalArticle__scroll-indicator-container {
  $offset-x : 180px;
  height: 5px;
  position: absolute;
  bottom: 40px;
  right: $offset-x;
  background: var(--clr-accent-soft30);
  transition: width .5s var(--tk-easeinout);
}

.horizontalArticle__scroll-indicator {
  background: var(--clr-accent-soft);
  height: 100%;
  width: 0px;
}

.horizontalArticle__scroll-indicator-container,
.horizontalArticle__scroll-indicator {
  border-radius: 2.5px;
}
</style>