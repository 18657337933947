export default function AppInitializer() {
    const steps = [];

    return Object.freeze({
        start,
        addStep,
    })

    function addStep({ id, tasks = [] }) {
        steps.push({ id, tasks });
        return this;
    }

    async function start(beforeStart = () => { }) {
        beforeStart();

        for (const step of steps) {
            //console.log('step', step, step.tasks.map(e => e.id))
            await Promise.all(step.tasks.map(e => {
                console.log('App initializing:\n =>', e.id);
                const promise = e.execute()
                promise
                    .then(() => {
                        console.log('Completed initializing:\n =>', e.id);
                    })
                    .catch(e => {
                        console.log('Failed initializing:\n =>', e.id);
                    })
                return promise;
            }))
        }
    }
}