import { createStore } from 'vuex'

//  Create a new store instance.
const store = createStore({
    state() {
        return {
            ready: false
        }
    },
    mutations: {
        ready(state) {
            state.ready = true;
        }
    },
    getters: {
        ready(state) {
            return state.ready
        }
    },
    actions: {
        ready(context) {
            context.commit('ready')
        },
    }
})

export default store;
