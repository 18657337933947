<template>
    <nav id="Breadcrumbs">
        <router-link v-for="crumb in breadcrumbs" :to="crumb.route" :key="crumb.route" v-html="crumb.title"></router-link>
    </nav>
</template>
<script>
export default {
    computed: {
        breadcrumbs() {
            this.$route;
            return app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined);
        }
    }
}
</script>
<style lang="scss">
#Breadcrumbs {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    
    color: var(--clr-light);
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);
    text-transform: var(--caption-transform);
    pointer-events: none;

    a {
        padding-right: 20px;
        pointer-events: all;
        position: relative;

        &::after {
            content: '/';
            position: absolute;
            pointer-events: none;
            margin-left: 8px;
            color: var(--clr-secondary);
        }

        &:last-of-type {
            cursor: default;
            padding-right: 0px;
            color: var(--clr-light);

            &::after {
                content: none;
            }

            &:hover,
            &:focus {
                color: var(--clr-light);
            }
        }

        &:hover,
        &:focus {
            color: var(--clr-accent2);
        }
    }
}
</style>