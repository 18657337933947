const config = {
    name: 'Systematic Defence',
    language: 'en',

    mediaFolder: '/media/',
    manifest: 'assets/json/@lang/manifest.json'
}


if (window.location.hostname === "localhost") {
    config.manifest = 'https://defence-showroom-umbraco.azurewebsites.net/output/defence/assets/json/@lang/manifest.json';
    config.mediaFolder = 'https://defence-showroom-umbraco.azurewebsites.net/media/';
} else if (window.location.hostname === "") {
    config.mediaFolder = 'media/';
}


export default config