<template>
  <section class="articleHeader" v-if="ready">

    <h2 class="articleHeader__spot-title">{{ content.spotTitle }}</h2>
    <h1 class="articleHeader__title">{{ content.title }}</h1>

    <div class="articleHeader__points-container">
      <div class="articleHeader__point" v-for="(point, index) in content.points" :key="point.title">
        <img :src="iconUrls[index]">
        <div class="articleHeader__text-container">
          <h3>{{ point.title }}</h3>
          <p>{{ point.text }}</p>
        </div>
      </div>
    </div>

  </section>
</template>

<script>

export default {
  props: [
    "content",
  ],
  data() {
    return {
      ready: false,
    }
  },
  computed: {
    iconUrls() {
      return this.content.points.map((point) => {
        return app.api.Utils.getMediaPath(point.icon.src);
      })
    }
  },
  mounted() {
    this.ready = true
  }
};
</script>

<style lang="scss">
// .articleHeader {
//   border: 1px solid blue;
//   .articleHeader__info-boxes {
//     border: 1px solid purple;
//   }
//   .articleHeader__info-box {
//     border: 1px solid red;
//   }
// }

.articleHeader {
  width: var(--article-header-width);
  height: 100%;
  padding-top: var(--article-top-padding);
  padding-bottom: var(--article-bottom-padding);
  overflow: hidden;
}

.articleHeader__spot-title {
  text-align: left;
  font-size: 17px;
  font-weight: var(--wgt-black);
  letter-spacing: 0.025em;
  line-height: 21px;
  color: var(--clr-secondary);
  text-transform: uppercase;
}

.articleHeader__title {
  text-align: left;
  font-size: 48px;
  font-weight: var(--wgt-regular);
  color: var(--clr-primary);
  line-height: 68px;
  margin-bottom: 80px;
}

.articleHeader__points-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 70px;
  row-gap: 50px;
  align-items: flex-start;
}

.articleHeader__point {
  width: 100%;
  display: flex;
  gap: 30px;

  img {
    // width: 65px;
    max-width: 65px;
    min-width: 65px;
    max-height: 65px;
    object-fit: contain;
  }

  // .articleHeader__text-container {
    
  // }

  h3 {
    grid-area: title;
    text-align: left;
    font-size: 16px;
    font-weight: var(--wgt-bold);
    color: var(--clr-primary);
    margin-bottom: 10px;
  }

  p {
    grid-area: text;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    font-weight: var(--wgt-regular);
    color: var(--clr-primary);
  }
}
</style>