<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot" v-show="ready" :to="hotspot.route" :class="getHotspotClasses" :style="[getPosition]" @click="hotspotPressed">
            <span v-html="hotspot.data.hotspotAlternativeTitle !== '' ? hotspot.data.hotspotAlternativeTitle : hotspot.data.title"></span>
            <div class="Hotspot__button">
                <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24"> <path d="M16.3787 11.4998L12.4291 7.5502L13.8433 6.13599L20.2071 12.4998L13.8433 18.8639L12.4291 17.4497L16.3788 13.4998H3.79297V11.4998H16.3787Z" fill="currentColor"/> </svg>
            </div>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--hidden': this.hotspot.data.hidden
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 44px;

.Hotspot {
    display: flex;
    gap: 2px;
    box-shadow: var(--shadow-hotspot);

    .Hotspot__button {
        height: $hotspot-height;
        width: $hotspot-height;
        padding: 11px;
        background-color: var(--clr-primary);
        display: flex;
        justify-content: center;
        align-items: center;
        
        >img,
        >svg {
            width: 100%;
            color: var(--clr-light);
        }
    }
    span {
        height: $hotspot-height;
        padding-inline: 20px; 
        background: var(--clr-primary);
        display: flex;
        align-items: center;
        color: var(--clr-light);
        font-size: var(--caption-size);
        font-weight: var(--caption-weight);
        text-transform: var(--caption-transform);
    }


    &--flipped {
        flex-direction: row-reverse;

        .Hotspot__button {
            transform: scaleX(-1);
        }
    }

    &:hover {
        .Hotspot__button {
            >svg {
                color: var(--clr-accent2);
            }
        }
        span {
            color: var(--clr-accent2);
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 1s var(--tk-easeinout);
}
.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}
.HotspotEntrance-enter-from {
    transform: translateY(-300%);
    opacity: 0;
}
.HotspotEntrance-leave-to {
    opacity: 0;
}
</style>