<template>
  <article class="Presenter" :style="[presenterPosition, scalePresenter]">
    <video id="intro" v-if="hasWorkingIntro && (properties.intro.mov || properties.intro.webm)" v-show="introPlaying" autoplay playsinline muted>
        <source :src="getMediaPath(properties.intro.mov.src)" type='video/mp4; codecs="hvc1"'> 
        <source :src="getMediaPath(properties.intro.webm.src)" type="video/webm">
    </video>
    <video id="idle" v-if="!mainPlaying && !introPlaying && (properties.idle.mov || properties.idle.webm)" :autoplay="!hasWorkingIntro" playsinline muted loop>
        <source :src="getMediaPath(properties.idle.mov.src)" type='video/mp4; codecs="hvc1"'> 
        <source :src="getMediaPath(properties.idle.webm.src)" type="video/webm">
    </video>
    <video id="main" v-if="mainPlaying && !introPlaying && (properties.main.mov || properties.main.webm)" playsinline>
        <source :src="getMediaPath(properties.main.mov.src)" type='video/mp4; codecs="hvc1"'> 
        <source :src="getMediaPath(properties.main.webm.src)" type="video/webm">
    </video>


    <transition name="fadeIn-text">
        <button class="hotspot Stream" :style="hotspotPosition" v-show="!mainPlaying && !introPlaying" @click="play">
            <div class="hotspot-button" style="animation-name: loopPulse; animation-iteration-count: infinite; animation-duration: 15s; animation-delay: 1.5s;"></div>
            <div class="hotspot__title_wrapper"><h2 v-html="properties.hotspotTitle"></h2></div> 
        </button>
    </transition>

    <transition name="fadeIn-text">
        <button class="hotspot skip" :style="hotspotPosition" v-show="mainPlaying && !introPlaying" @click="skip">
            <div class="hotspot-button" aria-hidden="true"></div>
            <div class="hotspot__title_wrapper"><h2>Stop</h2></div> 
        </button>
    </transition>
  </article>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData"
  ],
  data() {
    return {
      videos: {},

      introPlaying: false,
      mainPlaying: false
    }
  },
  created() {
    this.introPlaying = this.hasWorkingIntro ? true : false;
  },
  mounted() {
    this.videos = {
        intro: this.$el.querySelector("#intro"),
        idle: this.$el.querySelector("#idle"),
        main: this.$el.querySelector("#main")
    }

    this.events = {
        "introEnded": () => {
            this.videos.idle.currentTime = 0;
            this.videos.idle.play();
            this.introPlaying = false;
            console.log("INTRO ENDED");
        },
        "ended": () => {
            this.mainPlaying = false;

            app.radio.$emit("hotspot-animation-cycle-enable");

            touchKit.TrackingManager.track(touchKit.TrackingManager.events.CONTENT_CLICKED, {
                type: 'PresenterWatchedToEnd',
                info: this.properties.main
            });
        },

        "loadedmetadata": () => {
            console.log("loadedmetadata", this.videos.idle.videoWidth)
            this.$el.style.width = this.videos.idle.videoWidth + "px";
            this.$el.style.height = this.videos.idle.videoHeight + "px";
        }
    };

    if (this.videos.intro) {
        this.videos.intro.addEventListener("ended", this.events.introEnded)
    }
    if (this.videos.idle) {
        this.videos.idle.addEventListener("loadedmetadata", this.events.loadedmetadata)
    }
    if (this.videos.main) {
        this.videos.main.addEventListener("ended", this.events.ended)
    }

    this.preloadMainVideo();
  },
  destroy() {
        if (this.videos.intro) {
            this.videos.intro.removeEventListener("ended", this.events.introEnded)
        }
        this.videos.idle.removeEventListener("loadedmetadata", this.events.loadedmetadata)
        this.videos.main.removeEventListener("ended", this.events.ended)
        this.videos.forEach(v => v.pause())
  },
  methods: {
      play() {
          this.videos.main.play();
          this.videos.idle.currentTime = 0;
          this.mainPlaying = true;

          app.radio.$emit("hotspot-animation-cycle-disable");
          app.radio.$emit("chevron-playing");

          touchKit.TrackingManager.track(touchKit.TrackingManager.events.CONTENT_CLICKED, {
              type: 'PresenterStarted',
              info: this.properties.main
          });
      },
      skip() {
          this.videos.main.pause();
          this.videos.main.currentTime = 0;
          this.mainPlaying = false;

          app.radio.$emit("hotspot-animation-cycle-enable");

          touchKit.TrackingManager.track(touchKit.TrackingManager.events.CONTENT_CLICKED, {
              type: 'PresenterSkipped',
              info: this.properties.main
          });
      },

      preloadMainVideo() { 
        let i = 0;
        var urls = [
            {
                url: this.getMediaPath(this.properties.main.mov.src),
                mimetype: "video/mp4"
            },
            {
                url: this.getMediaPath(this.properties.main.webm.src),
                mimetype: "video/webm"
            }                
        ] 

        while(i < urls) {
            console.log(urls[i])

            var xhr = new XMLHttpRequest();
            xhr.open("GET", urls[i].url, true);
            xhr.responseType = "arraybuffer";
    
            xhr.onload = function(oEvent) {
                var blob = new Blob([oEvent.target.response], {type: urls[i].mimetype});
                video.src = URL.createObjectURL(blob);
            };
            xhr.send();
            
            i++;
        }
      },
      getMediaPath(src) {
        return app.api.Utils.getMediaPath(src)
      }
  },
  computed: {
      scalePresenter() {
          return {
              transform: `scale(${this.properties.presenterSize})`
          }
      },
      presenterPosition() {
          return {
              left: this.properties.presenterPosition.xPosition + "px",
              top: this.properties.presenterPosition.yPosition + "px"
          }
      },
      hotspotPosition() {
          return {
              left: this.properties.hotspotPosition.xPosition + "px",
              top: this.properties.hotspotPosition.yPosition + "px"
          }
      },
      hasWorkingIntro() {
          return !(this.properties.intro.mov === null || this.properties.intro.webm === null)
      }
  }
};
</script>

<style lang="scss" scoped>
.Presenter {
  position: absolute;

  video {
    position: absolute;
    top:0;
    left: 0;
  }
}
</style>