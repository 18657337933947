<template>
  <AppLoaderVue v-if="!ready"></AppLoaderVue>
  <AppContainer v-else></AppContainer>
</template>

<script>
import AppLoaderVue from './components/core/AppLoader.vue';
import AppContainer from './components/core/AppContainer.vue';

export default {
  components: {
    AppLoaderVue,
    AppContainer
  },
  created() {
    window.app.vm = this;
  },
  computed: {
    ready() {
      return this.$store.getters.ready;
    }
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100vh;
  max-width: 100vw;
  scroll-behavior: smooth;
  background-color: var(--dark-grey);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

img, button, a {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.CookieDeclaration {
  display: none !important;
}


// Global Styling 


// Scrollbar
* {
  scrollbar-color: var(--dark-grey) var(--light-grey);
  scrollbar-width: thin;

  & *::-webkit-scrollbar {
    width: 8px;
  }

  & *::-webkit-scrollbar-track {
    background: var(--light-grey);
  }

  & *::-webkit-scrollbar-thumb {
    background: var(--dark-grey);
    height: 50%;
  }
}

h1 {
  font-size: 57px;
  font-weight: 700;
}


h2 {
  font-size: 44px;
  font-weight: 700;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 32px;
  font-weight: 700;
}

h5 {
  font-size: 26px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  line-height: 22px;
}

p {
  font-size: 16px;
  line-height: 22px;

  >a {
    display: inline;
    color: var(--clr-secondary)
  }
}

strong, b {
  font-weight: bold;
}

em {
  font-style: italic;
}


button {
  cursor: pointer;

  &--disabled {
    cursor: default;
    pointer-events: none;
  }
}
</style>